import { RouteLocationRaw } from '#vue-router';
import { JobDetailsSource } from '@borg/types';

type JobQuery = { source?: JobDetailsSource } & Record<string, string | undefined>;

export function getJobLocation(id: string, slug: string, query?: JobQuery): RouteLocationRaw {
  return {
    name: 'job-jobId-slug-details',
    params: { jobId: id, slug },
    query,
  };
}

export function getJobApplicationLocation(
  id: string,
  slug: string,
  query?: JobQuery,
): RouteLocationRaw {
  return {
    name: 'job-jobId-slug-apply',
    params: { jobId: id, slug },
    query,
  };
}

export function getExternalJobApplicationFormLocation(
  id: string,
  slug: string,
  query?: JobQuery,
): RouteLocationRaw {
  return {
    name: 'job-jobId-slug-apply-external-form',
    params: { jobId: id, slug },
    query,
  };
}

export function getExternalJobApplicationLocation(
  id: string,
  slug: string,
  query?: JobQuery,
): RouteLocationRaw {
  return {
    name: 'job-jobId-slug-apply-external-countdown',
    params: { jobId: id, slug },
    query,
  };
}

export function navigateToJob(id: string, slug: string, query?: JobQuery, options?: Options) {
  return navigate(getJobLocation(id, slug, query), options);
}

export function navigateToJobApplication(
  id: string,
  slug: string,
  query?: JobQuery,
  options?: Options,
) {
  return navigate(getJobApplicationLocation(id, slug, query), options);
}

export function navigateToExternalJobApplication(
  id: string,
  slug: string,
  userLoggedIn: boolean,
  query?: JobQuery,
  options?: Options,
) {
  // TODO - remove after testing phase
  // flag to easily disable the new external application step, as the plan is to disable it after 7d and then decide if we should use it
  const DISABLE_EXTERNAL_INFO_FORM = true;
  const location =
    DISABLE_EXTERNAL_INFO_FORM || userLoggedIn || query?.applicationType === 'direct_link'
      ? getExternalJobApplicationLocation(id, slug, query)
      : getExternalJobApplicationFormLocation(id, slug, query);
  return navigate(location, {
    external: true,
    open: { target: '_blank' },
    ...options,
  });
}

type Options = Parameters<typeof navigateTo>[1] & {
  translate?: boolean;
};

export function navigate(to: RouteLocationRaw, options?: Options) {
  const translate = options?.translate ?? true;
  const lp = useLocalePath();

  if (translate) {
    return navigateTo(lp(to), options);
  }

  return navigateTo(to, options);
}
